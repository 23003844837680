import axios from "axios";

const develop = {
	username: process.env.REACT_APP_AWS_USERNAME,
	password: process.env.REACT_APP_AWS_PASSWORD,
	client_id: process.env.REACT_APP_AWS_CLIENT_ID,
	client_secret:process.env.REACT_APP_AWS_CLIENT_SECRET,
	grant_type: "password",
};
const staging = {
	username: process.env.REACT_APP_AWS_USERNAME,
	password: process.env.REACT_APP_AWS_PASSWORD,
	client_id: process.env.REACT_APP_AWS_CLIENT_ID,
	client_secret:process.env.REACT_APP_AWS_CLIENT_SECRET,
	grant_type: "password",

};

export const authorisationApi = async () => {
	const formData = new FormData();

	const data = staging

	Object.keys(data).map((item) => {
		formData.append(item, data[item]);
	});

	const result = await axios.post(
		process.env.REACT_APP_PUBLIC_TOKEN,
		formData,
	);

	localStorage.setItem("auth", JSON.stringify(result.data));
	return result;
};
