import EmptyData from "components/EmptyData";
import Header from "components/Header";
import React from "react";
import { Row, Col } from "react-bootstrap";
import icon404 from "assets/images/404.svg";
import { useSearchParams, useNavigate } from "react-router-dom";
import { useAuth } from "Utils/auth";

const PageNotFound = () => {
	const { user } = useAuth()
  const navigate = useNavigate();
	return (
		<div id="wrapper">
			{user ? <Header />:null}
			<section>
				<Row className="mx-0"> 
					<Col>
						<EmptyData
							title={"Page Not Found"}
							para={"Sorry, it looks like the page get lost"}
							buttonTitle={"Go Back to Home"}
							icon={icon404}
							onClick={async (e)=> navigate("/", { replace: true })}
						/>
					</Col>
				</Row>
			</section>
		</div>
	);
};

export default PageNotFound;
