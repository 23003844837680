import { useState } from "react";
import { toast } from "react-toastify";
import { useAuth } from "Utils/auth";

export default (apiFunc, scb = () => null, ecb = () => null) => {

	const [data, setData] = useState(null);
	const [error, setError] = useState("");
	const { setCommonLoader } = useAuth();

	const request = async (...args) => {
		setCommonLoader(true);
		try {
			const result = await apiFunc(...args);
			if(result.data.status === 200){
				setData(result.data.response);
				console.log("results of api", result.data.response)
				scb();
			}else{
				toast.error(result.data.message || "Unexpected Error!")
				setError(result.data.message || "Unexpected Error!");
			}
			// setData(result.data);
			
		} catch (err) {
      
			let error = ""
			const errorKeys = []
			// Object.keys(err.response.data).map(item=> errorKeys.push(item))
			// error = err.response.data[errorKeys[0]][0]
			toast.error(error)
			// setError(err.response.data || "Unexpected Error!");
			
		} finally {
			setCommonLoader(false);
		}
	};
	return {
		data,
		error,
		request,
	};
};
