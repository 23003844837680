import axios from "axios";

const apiClient = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL
});

// apiClient.interceptors.request()
apiClient.interceptors.request.use(
  config => {
    
    let { access_token } = JSON.parse(localStorage.getItem("user")) || JSON.parse(localStorage.getItem("auth")) || {};
    
    if (access_token) {
      config.headers['Authorization'] = 'Bearer ' + access_token
    }
    // config.headers['Content-Type'] = 'application/json';
    return config
  },
  error => {
    Promise.reject(error)
  }
)
export default apiClient;

