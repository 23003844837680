import Header from "components/Header";
import React from "react";
import { Row, Col, Card } from "react-bootstrap";
import { Outlet } from "react-router-dom";
const ManageContent = () => {
	return (
		<div id="wrapper">
			<Header />
			<section id="content-wrapper">
				<Row>
					<Col>
						<h3 className="content-title">Manage Content</h3>

						<Card className="box-shadow px-3 py-4">
							<Outlet />
						</Card>
					</Col>
				</Row>
			</section>
		</div>
	);
};

export default ManageContent;
