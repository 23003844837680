import React from "react";
import CustomButton from "components/Atoms/Button";
import addIcon from "assets/images/dyf_add_icon.svg";
import noCustomerAdded from "assets/images/no-customer-add.svg";

const EmptyData = (props) => {
	const {
		title = "",
		para = "",
		buttonTitle = "",
		icon = noCustomerAdded,
		onClick = () => null,
	} = props;
	return (
		<div className="position-relative h-70">
			<div className="no-customer d-flex">
				<img src={icon} alt="" className="mb-3" />
				<h3 className="mb-2">{title}</h3>
				{buttonTitle && <><p>{para}</p>
				<CustomButton
					type="button"
					text={buttonTitle}
					className="btn btn-primary add-btn"
					icon={addIcon}
          onClick={onClick}
				/></>}
			</div>
		</div>
	);
};

export default EmptyData;
