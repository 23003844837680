import React, { lazy, Suspense } from "react";
import { Navigate } from "react-router-dom";
import { withLazyComponent } from "components/withSuspense";
import PageNotFound from "Pages/PageNotFound";
import ManageContent from "Pages/ManageContent";
import AboutUs from "Pages/ManageContent/AboutUs";
import Policies from "Pages/ManageContent/Policies";

const SignIn = withLazyComponent(lazy(() => import("../Pages/SignIn")));
const ManageCustomers = withLazyComponent(
	lazy(() => import("../Pages/ManageCustomers")),
);
const ManageCategories = withLazyComponent(
	lazy(() => import("../Pages/ManageCategories")),
);
const ManageVideo = withLazyComponent(
	lazy(() => import("../Pages/ManageVideo")),
);
const AddVideos = withLazyComponent(lazy(() => import("../Pages/AddVideos")));
const ForgotPassword = withLazyComponent(
	lazy(() => import("../Pages/ForgotPassword")),
);
const Settings = withLazyComponent(lazy(() => import("../Pages/Settings")));
// const ManageContent = withLazyComponent(lazy(() => import("../Pages/ManageContent")));
// const Policies = withLazyComponent(lazy(() => import("../Pages/ManageContent/Policies")));
// const AboutUs = withLazyComponent(lazy(() => import("../Pages/ManageContent/AboutUs")));
const Nav = withLazyComponent(lazy(() => import("../Pages/ManageContent/Nav")));

export const allRoutes = (isLoggedIn, loader) => {
	return [
		{
			path: "*",
			element: <PageNotFound />,
		},
		{
			path: "authenticate",
			element: loader ? <></> : isLoggedIn ? <Navigate to="/" /> : <SignIn />,
		},
		{
			path: "forgot-password",
			element: loader ? (
				<></>
			) : isLoggedIn ? (
				<Navigate to="/" />
			) : (
				<ForgotPassword />
			),
		},
		{
			path: "/",
			element: loader ? (
				<></>
			) : isLoggedIn ? (
				<ManageCustomers />
			) : (
				<Navigate to="/authenticate" />
			),
		},
		{
			path: "manage-categories",
			element: loader ? (
				<></>
			) : isLoggedIn ? (
				<ManageCategories />
			) : (
				<Navigate to="/authenticate" />
			),
		},
		{
			path: "manage-video",
			element: loader ? (
				<></>
			) : isLoggedIn ? (
				<ManageVideo />
			) : (
				<Navigate to="/authenticate" />
			),
		},
		{
			path: "add-video",
			element: loader ? (
				<></>
			) : isLoggedIn ? (
				<AddVideos />
			) : (
				<Navigate to="/" />
			),
		},
		{
			path: "settings",
			element: loader ? <></> : isLoggedIn ? <Settings /> : <Navigate to="/" />,
		},
		{
			path: "manage-content",

			element: loader ? (
				<></>
			) : isLoggedIn ? (
				<ManageContent />
			) : (
				<Navigate to="/" />
			),
			children: [
				{
					path: "",
					element: <Nav />,
				},
				{
					path: "policies",
					element: loader ? (
						<></>
					) : isLoggedIn ? (
						<Policies />
					) : (
						<Navigate to="/" />
					),
				},
				{
					path: "about-us",
					element: loader ? (
						<></>
					) : isLoggedIn ? (
						<AboutUs />
					) : (
						<Navigate to="/" />
					),
				},
			],
		},
	];
};
