import { useState, useContext, createContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { authorisationApi } from "api/token";

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
	const navigate = useNavigate();

	const [user, setUser] = useState(false);
	const [loader, setLoader] = useState(true);
	const [commonLoader, setCommonLoader] = useState(false);
	const [toggle, setToggle] = useState(true)

	useEffect(() => {
		const value = JSON.parse(localStorage.getItem("user"));
		if (value && !user) {
			setUser(value);
		} else {
			authorisationApi();
		}
		setLoader(false);
	}, [user]);


	const login = (user) => {
		setUser(user);
		if (user) {
			localStorage.setItem("user", JSON.stringify(user));
		}
	};
	const logout = async () => {
		setUser(false);
		localStorage.clear();
	};
	return (
		<AuthContext.Provider
			value={{
				user,
				login,
				logout,
				loader,
				setLoader,
				commonLoader,
				setCommonLoader,
				toggle, setToggle
			}}
		>
			{children}
		</AuthContext.Provider>
	);
};

export const useAuth = () => {
	console.log("AuthContext", useContext(AuthContext))
	return useContext(AuthContext);
};
