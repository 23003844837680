import { useRoutes } from "react-router-dom";
import { allRoutes } from "Utils/routePath";
import { useAuth } from "Utils/auth";
import "assets/scss/index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/scss/global.scss";

window.Buffer = window.Buffer || require("buffer").Buffer;

function App() {
	const { user, loader } = useAuth();
	const isLoggedIn = user;
	const routing = useRoutes(allRoutes(isLoggedIn, loader));

	return (
		<>
			{/* <Header />  */}
			{routing}
		</>
	);
}

export default App;
