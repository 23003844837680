import React from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Button from "react-bootstrap/Button";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import { getContent, editContent } from "api/content";
import { useState } from "react";
import useApi from "Utils/service";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const AboutUs = () => {
	const getContentApi = useApi(getContent);
	const navigate = useNavigate();
	const editContentApi = useApi(editContent);
	const [content, setContent] = useState("");
	useEffect(() => {
		getContentApi.request("about-us/1/");
	}, []);

	useEffect(() => {
		if (editContentApi.data) {
			toast.success("Content updated successfully");
		}
	}, [editContentApi.data]);
	console.log("ses =>", getContentApi?.data?.content);
	console.log(editContentApi);
	console.log("editapi", editContentApi);

	return (
		<div className="policies">
			<h4>Terms and Conditions</h4>
			<CKEditor
				editor={Editor}
				data={getContentApi?.data?.content}
				onReady={(editor) => {
					// You can store the "editor" and use when it is needed.
					console.log("Editor is ready to use!", editor);
				}}
				config={{
					fontColor: {
						colors: [
							{
								color: "#FF75BC",
								label: "site",
							},

							{
								color: "#F4F4F4",
								label: "Dim grey",
							},
							{
								color: "#FFFFFF",
								label: "Dim grey",
							},
							{
								color: "#E2E2E2",
								label: "Dim grey",
							},
							// ...
						],
					},
				}}
				onChange={(event, editor) => {
					const data = editor.getData();
					console.log({ event, editor, data });
					setContent(data);
				}}
				onBlur={(event, editor) => {
					console.log("Blur.", editor);
				}}
				onFocus={(event, editor) => {
					console.log("Focus.", editor);
				}}
			/>

			<div className="d-flex mt-3">
				<Button className="me-3" variant="light" onClick={() => navigate(-1)}>
					Cancel
				</Button>
				<Button
					variant="primary"
					type="submit"
					onClick={() =>
						content
							? editContentApi.request("about-us/1/", { content })
							: toast.error("Content cannot be empty")
					}
				>
					Save
				</Button>
			</div>
		</div>
	);
};

export default AboutUs;
