import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import logo from "assets/images/logo.svg";
import FavIcon from "assets/images/favicon.svg";
import Avatar from "assets/images/Avatar.svg";
import users from "assets/images/users.svg";
import category from "assets/images/dyf_categories_icon.svg";
import video from "assets/images/dyf_videos_icon.svg";
import content from "assets/images/dyf_files_content.svg";
import backIcon from "assets/images/arrow_back_ios.svg";
import rightIcon from "assets/images/arrow_right_ios.svg";
import UserIcon from "assets/images/user-icon.svg";
import SettingIcon from "assets/images/settings.svg";
import LogOutIcon from "assets/images/login.svg";
import LeftArrow from "assets/images/dyf_back_icon.svg";
import logoutApi from "api/logoutApi";
import useApi from "Utils/service";
import { useAuth } from "Utils/auth";
import "./Header.scss";
import { Form } from "react-bootstrap";

const Header = (props) => {
  // const [toggle, setToggle] = useState(true);
  const postLogOutApi = useApi(logoutApi.logOutApi);
  const { logout, user, toggle, setToggle } = useAuth();
  useEffect(() => {
     if (postLogOutApi.data) {
      logout();
    }
  }, [postLogOutApi]);
  return (
    <div id="header-wrapper" className={toggle ? "" : "toggled"}>
      <aside id="sidebar-wrapper">
        <div className="sidebar-brand">
          <div className="brands">
            <img
              src={logo}
              alt="logo"
              style={{ width: "124px" }}
              className={!toggle ? "" : "large-logo"}
            />
            <div className="small-brand">
              <img
                src={FavIcon}
                alt="logo"
                className={!toggle ? "small-logo" : ""}
              />
            </div>
          </div>
        </div>
        <ul className="sidebar-nav">
          <li>
            <NavLink to="/">
              <span className="icon">
                <img src={users} alt="" />
              </span>
              <span className="title pl-16">Manage Customers</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/manage-categories">
              <span className="icon">
                <img src={category} alt="" />
              </span>
              <span className="title pl-16">Manage Categories</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/manage-video">
              <span className="icon">
                <img src={video} alt="" />
              </span>
              <span className="title pl-16">Manage Videos</span>
            </NavLink>
          </li>
          <li>
      
          <NavLink to="/manage-content">
              <span className="icon">
                <img src={content} alt="" />
              </span>
              <span className="title pl-16">Manage Content</span>
            </NavLink>
          </li>
        </ul>
      </aside>
      <div id="navbar-wrapper">
        <nav className="navbar navbar-white">
          <div className="container-fluid p-0">
            <div className="navbar-header">
              <div className="nav-left">
                <a
                  className="navbar-brand"
                  id="sidebar-toggle"
                  onClick={() => setToggle(!toggle)}
                >
                  <img src={toggle ? backIcon : rightIcon} alt="icon" />
                </a>
                {props.backButton ? (
                  <NavLink to="/manage-video">
                    <span className="icon">
                      <img src={LeftArrow} alt="" />
                    </span>
                    <span className="back-link pl-8">Back</span>
                  </NavLink>
                ) : null}
              </div>
              
              
              <Dropdown>
                <Dropdown.Toggle
                  id="dropdown-custom-1"
                  variant="link"
                  className="dropdown-custom"
                >
                  <div className="right-content d-flex">
                    <div className="avatar">
                      <img src={user?.profile_image} alt="avatar" />
                    </div>
                    <div className="data">
                      <p className="name text-capitalize">
                        {user?.firstname} {user?.lastname}
                      </p>
                      <p className="designation">Admin</p>
                    </div>
                  </div>
                </Dropdown.Toggle>
                <Dropdown.Menu className="super-colors">
                 
                  <Dropdown.Item>
                    <Link to="/settings" style={{color:"#212529", fontWeight: 400}}>
                    <img src={SettingIcon} alt="" />
                      Settings
                    </Link>
                    
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => postLogOutApi.request()}>
                    <img src={LogOutIcon} alt="" />
                    Logout
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
};
export default Header;