import React from "react";
import addIcon from "../../assets/images/dyf_add_icon.svg";

const Button = ({
	type = "button",
	text ,
	className = "btn btn-primary",
	icon = addIcon,
  onClick=()=>null
}) => {
	return (
		<button type={type} className={className} onClick={onClick}>
			<span className="p-1">
				<img src={icon} alt="icon" />
			</span>
			{text}
		</button>
	);
};

export default Button;
