import React from 'react';
import { RotatingLines } from  'react-loader-spinner'
import { useAuth } from 'Utils/auth';

const Loader = (props) => {
  const {user, loader, commonLoader} = useAuth()
  const loaderStatus = loader || props.loader || commonLoader
  return loaderStatus?<div className='loader-container'>
         <RotatingLines
          strokeColor="white"
          strokeWidth="5"
          animationDuration="0.95"
          width="96"
          visible={true}
        />
      <h4 className="loader-message">Processing Please Wait...</h4>
    </div>:null
};

export default Loader;